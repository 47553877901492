/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react'
import Hammer from 'hammerjs'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Box, Text, Heading } from '@chakra-ui/react'
import { useAnimations, useSwipeCard } from '../../hooks'
import { replaceableActivitiesState, replacedActivitiesState } from '../../atoms/state'
import { Icon } from '../Theme'
import { CLOUDINARY_URL } from '../../constants'
import RemoveOrReplace from './RemoveOrReplace'
import styles from './index.module.scss'

const getDescriptionPreview = (activity) => activity.description.length > 135
    ? `${activity.description.substring(0, 135)}...`
    : activity.description

const getCover = (activity) => activity.medias && activity.medias[0] && activity.medias[0].standart && activity.medias[0].standart.url || activity.medias[0].standard_resolution && activity.medias[0].standard_resolution.url

const Card = ({
    removeOption=false,
    index,
    data,
    className,
    style,
    getFormattedActivities = () => {},
    onClick = () => {},
}) => {
    const descriptionBox = useRef()
    const el = useRef()
    const [descriptionBoxHeight, setDescriptionBoxHeight] = useState()
    const {
        handleTap,
        handlePanLeft,
        handleTanRight,
    } = useSwipeCard(el)
    const history = useHistory()
    const {animations, setAnimations} = useAnimations()
    const [replacedActivities, setReplacedActivities] = useRecoilState(replacedActivitiesState)
    const [replaceableActivities, setReplaceableActivities] = useRecoilState(replaceableActivitiesState)
    const [activity, setActivity] = useState(data)
    const [removeDialog, toggleRemoveDialog] = useState(false)
    const [swiped, setSwiped] = useState(0)
    const [close, setClose] = useState(false)
    const desktop = (window.innerWidth > 768)

    const onRemove = (e) => {
        if (!replaceableActivities[swiped + 1]) {
            return alert('No more activities')
        }
        if (e.preventDefault) e.preventDefault()
        if (e.stopPropagation) e.stopPropagation()

        setClose(2)
        setTimeout(() => {
            setSwiped(swiped => swiped + 1)
            setClose(3)

        }, 500)
        setTimeout(() => {
            setClose(0)
            toggleRemoveDialog(false)
        }, 1000)
    }

    useEffect(() => {
        // DISCUS
        const style = window.getComputedStyle(descriptionBox.current, null)
        const fontSize = parseInt(style.getPropertyValue('font-size'))
        const lineHeight = parseInt(style.getPropertyValue('line-height'))

        setDescriptionBoxHeight(fontSize + lineHeight * 4)
    }, [descriptionBoxHeight, descriptionBox])

    useEffect(() => {
        handleTap(e => {
            if(e.target.dataset.dialog) return toggleRemoveDialog(e.target.dataset.dialog === 'true')
            if(e.target.dataset.replace) return onRemove(e)
            close !== 1 ? onClick(index) : !desktop && onRemove(e)
        })

        handlePanLeft(e => {
            if(desktop) return
            const keep = Math.abs(e.deltaX) < 40 || Math.abs(e.velocityX) < 0.25
            if (e.center.y < 30 || keep) {
                return
            }
            if (!replaceableActivities[swiped + 1]) {
                return alert('No more activities')
            }
            setClose(1)
        })

        handleTanRight(() => {
            setClose(0)
        })
    }, [activity._id, el, history, close, replaceableActivities, swiped])


    useEffect(() => {
        if (index > 0 || desktop ||  !removeOption) return
        const timeOutOpen = setTimeout(() => {
            setClose(1)
        }, 3500)

        const timeOutClose = setTimeout(() => {
            setClose(0)
        }, 5500)

        return () => {
            clearTimeout(timeOutClose)
            clearTimeout(timeOutOpen)
        }
    }, [index])

    useEffect(() => {
        if (!swiped ||  !removeOption) return
        let newAnimations = animations ? [...animations] : []
        let newReplacedActivities = replacedActivities ? [...replacedActivities] : []
        newAnimations[index] = getFormattedActivities([replaceableActivities[swiped - 1]])[0]
        newReplacedActivities[index] = replaceableActivities[0]
        setReplacedActivities(newReplacedActivities)
        setAnimations({activeIndex: 0, all: newAnimations})
        setActivity(replaceableActivities[0])
        setReplaceableActivities(replaceableActivities => replaceableActivities.filter((activity, index) => index !== 0))
    }, [swiped, index])

    const { name } = activity

    const description = getDescriptionPreview(activity)

    const cover = getCover(activity)

    return (
        <Box
            overflow="hidden"
            position="relative"
            overflow="hidden"
            mb="8"
            w="full"
            className={className}
            style={style}>
            <Box
                ref={el}
                position="relative"
                color="black.100"
                bg="white.100"
                className={styles.card}
                >
                <div className={styles.imgContainer}>
                    <div
                        data-close={close}
                        onClick={(e) => close === 1 && onRemove(e)}
                        className={styles.swipe}>
                        <div>
                            <span>
                                <Icon
                                    type="light/arrow-left"/>
                                <i>
                                    Click to replace
                                </i>
                            </span>
                        </div>
                    </div>

                    {cover && (
                        <div
                            data-close={close}
                            className={styles.imageWraper}>
                            <img
                                key={activity._id}
                                // onLoad={onImageLoad}
                                src={`${CLOUDINARY_URL}/w_620,f_auto/${cover}`}
                                alt={name}
                                className={styles.img}/>
                            {(removeDialog && removeOption) ? (
                                <RemoveOrReplace/>
                            ) : (removeOption && desktop) && (
                                <div>
                                    <Icon
                                        style={{
                                            width: '40px',
                                            position: 'absolute',
                                            color: 'white',
                                            background: 'black',
                                            borderBottomRightRadius: '15%',
                                            borderTopLeftRadius: '15%',
                                            right: '0',
                                            bottom: '0',
                                            padding: '10px',
                                        }}
                                        type="light/trash"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Heading
                    as="h5"
                    data-close={close}
                    fontSize="md">
                    {name}
                </Heading>
                <Text 
                ref={descriptionBox}
                h={descriptionBoxHeight}
                data-close={close}
                >
                    {description}
                </Text>
            </Box>
            <div
                data-close={close}
                onClick={onRemove}
                className={styles.swipe}>
                <div>
                    <span>
                        <Icon
                            type="light/arrow-left"/>
                        <i>
                            Click to replace
                        </i>
                    </span>
                </div>
            </div>
        </Box>
    )
}

export default Card
