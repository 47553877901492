import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import useGetPedSettings from '../hooks/useGetPedSettings'
import LoadingPage from '../components/LoadingPage'
import { CLOUDINARY_URL, COVERS, KEYWORDS } from '../constants'
import { ROUTES } from '../components/App/Component'

const PedSettingsContext = React.createContext(null)

export const usePedSettings = () => {
    const pedSettings = useContext(PedSettingsContext)
    return pedSettings
}

const withPedSetting = (Component) => ({ ...props }) => {
    const routes = ROUTES.map(route => route.path)
    const subdomain = window.location.pathname.split('/')[1]
    const dataset = !routes.includes(`/${subdomain}`) ? subdomain : null
    const {data: response, error, fetch} = useGetPedSettings()
    const rejectedImages = useRef({})
    const [data, setData] = useState()
    const [cached, setCached] = useState(false)

    const cacheCovers = useCallback((fetchedCovers) => {
        const covers = Object.keys(COVERS)

        console.log('covers', covers)
        if(!covers || covers.length === 0) return setCached(true) // TODO need cahe default covers

        let resourceForCache = []
        const cacheControl = (keyItem, isSuccess) => {
            resourceForCache[keyItem].state = true
            if (!isSuccess) {
                rejectedImages.current.resourceForCache[keyItem].name = null 
            }
            const other = resourceForCache.find(key => key.state === false)
            if(!other) {
                setCached(true)
            }
        }
        

        covers.forEach((cover, i) => {
            // if(response[cover]) {
                const image = new Image()

                resourceForCache.push({
                    name: cover,
                    state: false,
                })
                console.log('cover in process: ', cover, response ? response[cover] : COVERS[cover])
                image.src = response && response[cover] ? `${CLOUDINARY_URL}/${response[cover]}` : COVERS[cover]
                image.onload = () => cacheControl(i, true)
                image.error = () => cacheControl(i, false)
                
            // }
        })    

    }, [response])

    const cacheKeywords = (keywords) => {
        // console.log(KEYWORDS.flat())
        const keys = KEYWORDS.flat()
       

        keys.forEach((key) => {
            const image = new Image()
            if(keywords[key]) {
                // console.log('cober in process', `${CLOUDINARY_URL}/${keywords[key]}`)
                image.src = `${CLOUDINARY_URL}/${keywords[key]}`
                
            } else {
                // console.log('cober in process', `/images/${key}.jpg}`)

                image.src = `/images/${key}.jpg}`
            }
        })    
    }

    useEffect(() => {
        fetch(subdomain)
    }, [subdomain, fetch])

    useEffect(() => {
        console.log(response, 'response')
        if (response === undefined) return
        if (response === null) {
            setData({})
        }

        const covers = Object.keys(response || {}).filter(key => 
            response[key] 
            && typeof response[key] === 'string' 
            && response[key].includes('.s3.amazonaws.com')
        )

        setData(response)
        cacheCovers(covers)
        cacheKeywords(response ? response?.keywords : {})
    }, [response])

    return (
        <PedSettingsContext.Provider value={{...data, ...rejectedImages.current, dataset}}>
            {(data === undefined || !cached)
                ? <LoadingPage/>
                : <Component {...props}/>}
        </PedSettingsContext.Provider>
    )
}

export default withPedSetting
