/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Marker } from 'react-mapbox-gl'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { MAPBOX_ACCESS_TOKEN } from '../../../constants'
import http from '../../../http'
import Pin from './Pin'

export default function MarkersOverlay ({
    currentLocation,
    activities,
    selectedActivity,
    calculateDistance,
    showRoute,
    profiles,
    details,
    geometries,
    getDuration,
    loaded,
}) {
    const viewport ={
        width: window.innerWidth,
        height: window.innerHeight
    }
    const [duration, setDuration] = useState(null)
    const [profile, setProfile] = useState(0)
    const [timeout, changeTimeout] = useState(null)

    const updateDuration = (coordinates) => {
        if (timeout) {
            clearTimeout(timeout)
            changeTimeout(null)
        }

        changeTimeout(setTimeout(() => {
            getProfile(coordinates)
        }, 500))
    }

    const getProfile = async (coordinates) => {
        try {
            const response = await Promise.all(profiles.map(profile => http.get(`https://api.mapbox.com/directions/v5/mapbox/${profile.name}/${currentLocation.coordinates.join(',')};${coordinates.join(',')}`, {
                params: {
                    access_token: MAPBOX_ACCESS_TOKEN,
                    geometries: 'geojson'
                }
            })))
            if (profile.data) {
                setDuration(response.map(profile => getDuration(profile.data.routes[0].duration)))
            }
        } catch (e) {
            console.log(e)
            console.log('error getting duration')
        }
    }

    return (
        <div>
            {/* ACTIVITY LOCATION */}
            {activities.filter(activity => activity).map(activity => (
                <Marker
                    coordinates={activity.loc.coordinates}
                    anchor="bottom"
                    key={`activity-marker-${activity._id}`}
                    className={`activity-marker-${activity._id}`}>
                        <Pin
                            data={activity}
                            selectedActivity={selectedActivity}
                            currentLocation={currentLocation}
                            activity={activity}
                            viewport={viewport}
                            updateDuration={updateDuration}
                            calculateDistance={calculateDistance}/>
                </Marker>
            ))}

            {selectedActivity._id && currentLocation && (
                <div>
                    <div className="row no-gutters profiles-container">
                        {profiles.map((p, index) => (
                            <button
                                key={`profile-${index}`}
                                className="profile w-100"
                                data-profile={p.name}
                                data-active={profile === index}
                                onClick={() => { setProfile(index) }}>
                                    <img 
                                        src={p.icon}
                                        alt={p.alt}/>
                                    <br/>
                                    {profile === index && details[0].data[index] && (
                                        <span>{duration ? duration[profile] : details[0].data[index].duration}</span>
                                    )}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {showRoute && geometries[0].data.map((geometry, index) => (
                <div key={`geometry-${index}-${selectedActivity._id}`}>
                    <GeoJSONLayer
                        data={geometry}
                        lineLayout={{'line-join': 'round', 'line-cap': 'round'}}
                        linePaint={{ 'line-color': profile === index ? '#000000' : 'transparent', 'line-width': 3 }}/>
                </div>
            ))}

            {/* CURRENT LOCATION */}
            {currentLocation && (
                <Marker
                    coordinates={currentLocation.coordinates}
                    anchor="bottom">
                        <Pin
                            data={currentLocation.name}
                            showData={true}
                            active={true}
                            loaded={loaded}/>
                </Marker>
            )}
        </div>
    )    
}