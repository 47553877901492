/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box, Heading } from '@chakra-ui/react'
import Card from '../Card'
import Header from '../Header'
import Overlay from './Overlay'
import Loading from '../Loading'
// TODO: remove unused importes everywhere
// TODO: enable eslint everywhere, and fix all linting errors
import { useApi, useAnimations } from '../../hooks'
import { replaceableActivitiesState, activitiesState, replacedActivitiesState } from '../../atoms/state'
import styles from './index.module.scss'

const getFormattedActivities = (activities) => {
    const newAnimations = []
    activities.map(
        (activity) => {
            const data = []
            activity.medias.map(
                (media, index) => {
                    data.push({
                    _id: activity._id,
                    _explorer: activity._explorer,
                    _city: activity._city,
                    google_info: activity.google_info,
                    airport: activity.airport,
                    image: media.standard_resolution && media.standard_resolution.url,
                    name: activity.name,
                    loc: activity.loc,
                    nextSlide: activity.medias[index + 1] && activity.medias[index + 1].standard_resolution ? activity.medias[index + 1].standard_resolution.url : '',
                    description: activity.description
                })
            }
            )
            newAnimations.push(data)
        }
    )

    return newAnimations
}

const Favorites = () => {
    const location = useLocation()
    const history = useHistory()
    const {animations, setAnimations} = useAnimations()
    const [activities, setActivities] = useRecoilState(activitiesState)
    const [replacedActivities, setReplacedActivities] = useRecoilState(replacedActivitiesState)
    const setReplaceableActivities = useSetRecoilState(replaceableActivitiesState)
    const imageCached = useRef(0)
    const [render, toggleRender] = useState(false)
    const [loaded, setLoaded] = useState(activities ? true : false)
    const {data: activitiesResponse, fetch, loading} = useApi('REACT_APP_FAVORITES_API', 'GET', `${location.search}`)

    const onClick = (index) => {
        setAnimations({all: animations, activeIndex: index})
        history.push('/animations')
    }

    const remove = (index) => {
        setActivities(activities => activities.filter((a, i) => i !== index))
    }

    useEffect(() => {
        if (!location.search) {
            return history.push('/preferences')
        }
        if (activities) return
        fetch()
    }, [fetch, location, history, activities])

    useEffect(() => {
        if (!activitiesResponse) return
        window.localStorage.setItem('testingData', JSON.stringify(activitiesResponse.data[3]))
        const formattedActivities = getFormattedActivities(activitiesResponse.data)
        const activities = activitiesResponse.data.slice(0, 10)
        setReplaceableActivities(activitiesResponse.data.slice(10, 30))
        setAnimations({all: formattedActivities.slice(0, 10), activeIndex: 0})
        setActivities(activities)
        setReplacedActivities(activities)
    }, [activitiesResponse])

    useEffect(() => {
        if (!replacedActivities) {
            return toggleRender(true)
        }
        setActivities(replacedActivities)
        setAnimations({all: getFormattedActivities(replacedActivities), activeIndex: 0})
        toggleRender(true)
    }, [])
    
    if (!render) {
            return <></>
        }
        if (activities === null || loading || !loaded) {
            return <Loading setLoaded={() => setLoaded('overlay-dark')}/>
        }
    return (
        <div className={styles.wrapper}>
            {activities && <Overlay light={loaded !== 'overlay-dark'}/>}
            <Box
                bg="white.100"
                position="relative"
                className={styles.step3}>
                <Header/>
                <div className={styles.dataWrapper}>
                    <div className={styles.main}>
                        <Heading
                            as="h2"
                            fontSize="h2"
                            marginY=".69em"
                            className={styles.slideUp}
                            style={{animationDelay: '.5s'}}>
                            Your Favorite List
                        </Heading>
                    </div>
                    <div className={styles.cards}>
                        {activities.map(
                            (activity, index) => (
                                <Card
                                    index={index}
                                    data={activity}
                                    onClick={onClick}
                                    getFormattedActivities={getFormattedActivities}
                                    remove={() => remove(index)}
                                    className={styles.slideUp}
                                    style={{
                                        animationDelay: `${2 + index * .3}s`,
                                        borderRadius: 15,
                                    }}
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={activity._id}/>
                            ),
                        )}
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default Favorites
