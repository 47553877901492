import React, { useState } from 'react'
import styles from './index.module.scss'

const ExplorerAvatar = ({
    profilePicture,
}) => {
    const [showPlaceholder, togglePlaceholder] = useState(!profilePicture)
    const placeholderProfile = '/images/placeholder-profile.jpg'
    return (
        <div className={styles.avatar}>
            <img
                src={showPlaceholder ? placeholderProfile : profilePicture}
                alt="Creator Avatar"
                onError={() => togglePlaceholder(true)}/>
        </div>
    )
}

export default ExplorerAvatar
