import React from 'react'
import { Icon } from '../Theme'
import styles from './index.module.scss'
// import useDataset from '../../hooks/useGetPedSettings'

const LoadingPage = () => (
    <div className={styles.main}>
        <div className={styles.icon}>
            <Icon type="light/logo"/>
        </div>
    </div>
)

export default LoadingPage
