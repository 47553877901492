/* eslint-disable */
/** @jsx jsx */
import { useState, useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { jsx, css } from '@emotion/core'
import Modal from '../../../Modal'
import SendMeButton from '../SendMeButton'
import styles from './index.module.scss'

const classes = {
    fadeIn: css`
        animation: fadeIn 500ms forwards;
    `,
    fadeOut: css`
        animation: fadeOut 500ms forwards;
    `,
}

const Wrapper = ({
    onClose,
    isModal,
    visible,
    children,
    buttonStyle,
    buttonClassName,
    delay,
    onClick = () => {},
}) => {
    const [animate, setAnimate] = useState()
    const boxRef = useRef()

    const click = () => {
        if (!isModal) {
            return onClick()
        }
        setAnimate('fadeIn')
        onClick()
    }
    
    useEffect(() => {
        if (visible === 'shrink') {
            setAnimate('fadeOut')
            setTimeout(() => {
                setAnimate()
            }, 500)
        }
    }, [visible])

    useEffect(() => {
        boxRef.current.addEventListener('animationend', () => {
            boxRef.current.style.cssText = `animation: none; bottom: 0; ${ window.innerWidth < 600 ? 'justify-content: flex-end' : ''}`
          });
    }, [])

    return (
        <Box
            ref={boxRef}
            position="fixed"
            bg="white.100"
            w="full"
            className={`${styles.sendTo} ${visible === 'thanks' ? styles.fadeOut : ''}`}
            style={visible === 'thanks' ? {height: '100px', animationDelay: '2s', justifyContent: 'flex-end'} : {}}
            data-delay={delay}
            data-close={!isModal && visible === 'shrink'}
            data-open={!isModal && visible && visible !== 'thanks' ? true : false}
            data-is-modal={isModal}>
                {isModal && animate ? (
                    <Modal onClose={onClose}>
                        <Box
                            className={styles.modalOverlay}
                            css={classes[animate]}>
                                {children}
                        </Box>
                    </Modal>
                ) : children}
                {visible !== 'thanks' && (
                    <SendMeButton
                        style={buttonStyle}
                        className={buttonClassName}
                        onClick={click}
                    />
                )}
        </Box>
    )
}

export default Wrapper
