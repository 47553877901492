import React, { useRef, useEffect, useCallback } from 'react'
import styles from './index.module.scss'

const Input = ({
    id,
    className,
    placeholder,
    type,
    value,
    style,
    name,
    onChange = () => {},
    onValueChange = () => {},
    onEnter = () => {},
}) => {
    const throttling = useRef()

    const onNativeChange = (e) => {
        onChange(e)

        if (throttling.current) {
            clearTimeout(throttling.current)
            throttling.current = null
        }

        const { value: targetValue } = e.target
        throttling.current = setTimeout(() => {
            onValueChange(targetValue)
        }, 500)
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            onEnter()
        }
    }
    const blockUKeys = useCallback((e) => {
        if (type === 'number') {
            const keys = ['e', 'E']
            // eslint-disable-next-line no-unused-expressions
            keys.includes(e.key) && e.preventDefault()
        }
    }, [type])

    useEffect(() => {
        onChange({ target: { value } })
    }, [onChange, value])

    return (
        <input
            id={id}
            className={`${styles.input} ${className}`}
            style={style}
            type={type}
            name={name}
            onKeyDown={blockUKeys}
            onChange={onNativeChange}
            value={value}
            onKeyUp={onKeyUp}
            placeholder={placeholder}/>
    )
}

export default Input
