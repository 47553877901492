/* eslint-disable */
import { on } from 'hammerjs'
import React from 'react'
import ReactDOM from 'react-dom'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.ModalRoot = document.querySelector('#root')
        this.el = document.createElement('div')
        this.el.setAttribute('data-modal', 'true')
    }
  
    componentDidMount() {
        this.ModalRoot.appendChild(this.el)
        this.el.addEventListener('click', (e) => {
            if (e.target.parentElement.dataset.modal && this.props.onClose) this.props.onClose()
        })
    }
  
    componentWillUnmount() {
        this.ModalRoot.removeChild(this.el)
    }
  
    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        )
    }
}
  
export default Modal