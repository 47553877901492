import Component from './Component'
import { withRouter, withAnimations, withPedSetting } from '../../hocs'

export default withRouter(
    withPedSetting(
        withAnimations(
            Component,
        ),
    ),
)
