/** @jsx jsx */
/* eslint-disable */
import { useState, useEffect, Fragment } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { css, jsx } from '@emotion/core'
import { Icon } from '../Theme'
import styles from './index.module.scss'

const LOADING_VIDEO = 'https://res.cloudinary.com/dbur3sjwh/video/upload/v1601294382/animation_bvf3jg.mp4'

const classes = {
    slideUp: css`
        opacity: 0;
        animation: slideUp 1s forwards 1s;
    `,
    fadeIn: css`
        animation: fadeIn 1s forwards;
    `,
    fadeOut: css`
        animation: fadeOut 1s forwards;
    `,
    fadeOutDelay: css`
        animation: fadeOut 1s forwards 3s;
    `,
    hideToTop: css`
        animation: hideToTop 1s forwards;
    `,
    appearToTop: css`
        animation: appearToTop 1s forwards 200ms;
    `
}

const Loading = ({
    setLoaded
}) => {
    const [hidden, setHidden] = useState()
    const height  = window.innerHeight
    const [loadingCoverLoaded, setLoadingCover] = useState(false) 
    
    useEffect(() => {
        const loadingCover = new Image()

        loadingCover.src = window.innerWidth >= 600 ? '/images/loading.jpg' : '/images/sm/loading.jpg'

        loadingCover.onload = () => setLoadingCover(true)
    })

    useEffect(() => {
        if(!loadingCoverLoaded) return
        const timeout = setTimeout(() => {
            setHidden(true)
            setLoaded(true)
        }, 5500)

        return () => {
            setHidden(false)
            clearTimeout(timeout)
        }
    }, [loadingCoverLoaded])
    const desktop = (window.innerWidth > 768)

    if(!loadingCoverLoaded) return <Fragment></Fragment>

    return (
        <Fragment>
            {desktop ? 
            (<Flex
                px="20"
                justify="center"
                align="center"
                direction="column"
                css={hidden > 0 ? classes.fadeOutDelay : {}}
                className={styles.loading}>
                <Flex
                    w="238px"
                    h="212px"
                    position="relative"
                    className={styles.icons}
                    css={hidden > 0 ? classes.fadeOut : classes.fadeIn}
                    align="center"
                    justify="center"
                    zIndex="docked">
                    <Icon
                        className={styles.rotate}
                        type="light/spin"/>
                    <Icon type="light/loading"/>
                </Flex>
                <Text
                    mt="16"
                    fontSize="lg"
                    color="white.100"
                    textAlign="center"
                    zIndex="docked"
                    css={hidden > 0 ? classes.fadeOut : classes.slideUp}>
                    One moment while we create your unique, personal itinerary.
                </Text>
            </Flex>) :


            (<Flex
                css={classes.appearToTop}
                className={styles.gotItWrapper}>
                <Flex
                    height={`${height}px`}
                    justify="center"
                    align="center"
                    direction="column"
                    className={styles.gotIt}>
                        <div className={styles.wrapper}>
                            <div className={styles.container}>
                                <div className={styles.header}>
                                    <Icon
                                        type="light/ten-transparent"
                                    />
                                </div>
                                <div className={styles.lineWrapper}>
                                    <div
                                        className={styles.line}
                                        data-thick={true}
                                        data-length="long"
                                        style={{animationDelay: '400ms'}}/>
                                    <div
                                        className={styles.line}
                                        data-length="medium"
                                        style={{animationDelay: '600ms'}}/>
                                    <div
                                        className={styles.line}
                                        data-length="medium"
                                        style={{animationDelay: '800ms'}}/>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.data}>
                                        <span>New Content</span>
                                    </div>
                                    <div className={styles.swipeArea}>
                                        <div className={styles.content}>
                                            <Icon
                                                type="light/arrow-left"/>
                                            <span>Swipe left to remove and replace content</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.lineWrapper}>
                                    <div
                                        className={styles.line}
                                        data-thick={true}
                                        data-length="short"
                                        style={{animationDelay: '1200ms'}}/>
                                    <div
                                        className={styles.line}
                                        data-length="medium"
                                        style={{animationDelay: '1400ms'}}/>
                                    <div
                                        className={styles.line}
                                        data-length="medium"
                                        style={{animationDelay: '1600ms'}}/>
                                </div>
                            </div>
                        </div>
                </Flex>
            </Flex>)}
        </Fragment>
    )
}

export default Loading