/* eslint-disable */
/** @jsx jsx */
import { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/core'
import { Heading, Flex, List, ListItem, Link, Box } from '@chakra-ui/react'
import { Icon } from '../Theme'
import Header from '../Header'
import DarkLayer from '../DarkLayer'
import { useWindowSize, usePedSettings } from '../../hooks'
import { CLOUDINARY_URL } from '../../constants'
import { BACKGROUNDS } from '../../providers/backgroundProvider'
import styles from './index.module.scss'

const DATA = [
    { path: '', type: 'Business' },
    { path: '', type: 'Personal' },
]

const classes = {
    fadeOut: css`
        animation: fadeOut 1s forwards;
    `,
    headers: [
        css`
            opacity: 0;
            animation: slideDown 1s forwards;
            animation-delay: 0.5s;
        `,
        css`
            opacity: 0;
            animation: slideDown 1s forwards;
            animation-delay: 1s;
        `,
    ]
}

const TravelingFor = () => {
    const [mounted, setMounted] = useState()
    const [hidden, hide] = useState()
    const {width} = useWindowSize()
    const history = useHistory()
    const pedSetting = usePedSettings()

    const navigate = () => {
        hide(true)
        setTimeout(() => {
            history.push('/preferences')
        }, 1000)
    }

    useEffect(() => {
        setTimeout(() => {
            setMounted(true)
        }, 300)
    }, [setMounted])

    const coverStyle = {
        height: window.innerHeight,
        backgroundImage: pedSetting.travelingForImage ? `url(${CLOUDINARY_URL}/${pedSetting.travelingForImage}` : BACKGROUNDS['/traveling-for'] 
    }

    return (
        <Box height={window.innerHeight} className={styles.wrapper} >
            <Flex
                justify="space-between"
                direction="column"
                px={5}
                color="white.100"
                className={styles.step1}>
                <Box 
                    style={coverStyle}
                    className={styles.cover}/>
                    <DarkLayer/>
                    <Header
                        logoDark={false}
                        alignment="center"/>
                {mounted && (
                    <Fragment>
                        <Flex
                            justify="center"
                            direction="column"
                            flex="1">
                            <Heading
                                as="h1"
                                fontSize="h1"
                                className={styles.heading}
                                css={hidden && classes.fadeOut}>
                                {pedSetting.travelingForText || 'I am visiting Lanai for'}
                            </Heading>
                            <List
                                styleType="none"
                                className={styles.list}
                                p="0">
                                {DATA.map(
                                    ({ type }, index) => (
                                        <ListItem
                                            mt={12}
                                            key={type}>
                                            <Link
                                                d="flex"
                                                _hover="none"
                                                alignItems="center"
                                                className={styles.type}
                                                onClick={navigate}
                                                css={hidden ? classes.fadeOut : classes.headers[index]}>
                                                <Icon type="light/right"/>
                                                <Heading
                                                    m="0"
                                                    as="h3"
                                                    fontSize="h3"
                                                    fontWeight="400"
                                                    color="white.100">
                                                    {type}
                                                </Heading>
                                            </Link>
                                        </ListItem>
                                    ),
                                )}
                            </List>
                        </Flex>
                    </Fragment>
                )}
            </Flex>
        </Box>
    )
}

export default TravelingFor
