import { atom } from 'recoil'

export const slideAnimationState = atom({
    key: 'slideAnimationState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
})

export const replaceableActivitiesState = atom({
    key: 'replaceableActivitiesState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
})

export const activitiesState = atom({
    key: 'activitiesState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
})

export const replacedActivitiesState = atom({
    key: 'replacedActivitiesState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
})

export const uniqueExplorerState = atom({
    key: 'uniqueExplorerState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
})
