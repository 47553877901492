import React, { useState } from 'react'
import Lottie from 'react-lottie'
import { Flex } from '@chakra-ui/react'
import lottieLoadingData from '../../../lottie/loading.json'
import { Button, Icon } from '../../Theme'
import styles from './index.module.scss'

const Loading = ({
    onClose,
}) => {
    const [closing, toggleClosing] = useState()
    const options = {
        loop: true,
        autoplay: true,
        animationData: lottieLoadingData,
    }

    const close = () => {
        toggleClosing(true)
        setTimeout(() => {
            onClose()
        }, 1000)
    }

    return (
        <div className={styles.wrapper}>
            <Flex
                className={styles.loading}
                data-closing={closing}>
                <Button
                    transparent
                    className={styles.close}
                    onClick={close}>
                    <Icon type="light/close"/>
                </Button>
                <Lottie
                    options={options}
                    height={250}
                    width={250}/>
            </Flex>
        </div>
    )
}

export default Loading
