/* eslint-disable */

import { useEffect, useState } from 'react'
import Hammer from 'hammerjs'

const useSwipeCard = (el) => {
    const [onTap, setOnTap] = useState()
    const [onPanLeft, setOnPanLeft] = useState()
    const [onPanRight, setOnPanRight] = useState()

    useEffect(() => {
        if (!el) return

        const hammertime = new Hammer(el.current)

        if (onTap) hammertime.on('tap', onTap)
        if (onPanLeft) hammertime.on('panleft', onPanLeft)
        if (onPanRight) hammertime.on('panright', onPanRight)

        return () => hammertime.destroy()
    }, [onTap, onPanLeft, onPanRight, el])

    return {
        handleTap: (handler) => setOnTap(() => handler),
        handlePanLeft: (handler) => setOnPanLeft(() => handler),
        handleTanRight: (handler) => setOnPanRight(() => handler),
    }
}

export default useSwipeCard
