import React from 'react'
import { Button, Icon } from '../../../Theme'
import styles from './index.module.scss'

const NavigationButtons = ({
    slide,
    allSlides,
    closing,
    onPrev = () => {},
    onNext = () => {},
}) => (
    <div
        className={styles.wrapper}
        data-hide={closing}>
        {slide > 0 && (
            <Button
                className={`${styles.button} ${styles.prev}`}
                transparent
                onClick={onPrev}>
                <Icon
                    type="grey/arrow-left"/>
            </Button>
        )}
        {slide + 1 !== allSlides && (
            <Button
                className={`${styles.button} ${styles.next}`}
                transparent
                onClick={onNext}>
                <Icon
                    type="grey/arrow-right"/>
            </Button>
        )}
    </div>
)

export default NavigationButtons
