/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Box, Heading, Flex } from '@chakra-ui/react'
import { Icon } from '../Theme'
import Card from '../Card'
import ExplorerCard from '../ExplorerCard'
import Header from '../Header'
import Overlay from './Overlay'
import SendTo from './SendTo'
import Loading from '../Loading'
import { useApi, useAnimations, usePedSettings } from '../../hooks'
import { replaceableActivitiesState, activitiesState, replacedActivitiesState, uniqueExplorerState } from '../../atoms/state'
import { CLOUDINARY_URL } from '../../constants'
import styles from './index.module.scss'

const getFormattedActivities = (activities) => {
    const newAnimations = []
    activities.forEach(
        (activity) => {
            const data = []
            activity.medias
                .forEach(
                    (media, index) => data.push({
                        _id: activity._id,
                        _explorer: activity._explorer,
                        _city: activity._city,
                        google_info: activity.google_info,
                        airport: activity.airport,
                        image: media?.standard_resolution?.url,
                        name: activity.name,
                        loc: activity.loc,
                        nextSlide: activity.medias[index + 1] ? activity?.medias[index + 1]?.standard_resolution?.url : '',
                        description: activity.medias[index] ? activity?.medias[index]?.standard_resolution?.name || '' : '',
                    })
            )
            if (data.length > 0) newAnimations.push(data)
        }
    )

    return newAnimations
}

const Results = () => {
    const location = useLocation()
    const history = useHistory()
    const pedSettings = usePedSettings()
    const width = window.innerWidth
    const [tab, toggleTabs] = useState((history.location.state && history.location.state.tab) ? history.location.state.tab : 'activity')
    const {animations, setAnimations} = useAnimations()
    const [activities, setActivities] = useRecoilState(activitiesState)
    const [loaded, setLoaded] = useState(activities ? true : false)
    const [render, toggleRender] = useState(false)
    const [animate, toggleAnimate] = useState(true)
    const [firstRender, toggleFirstRender] = useState(true)
    const setReplaceableActivities = useSetRecoilState(replaceableActivitiesState)
    const [replacedActivities, setReplacedActivities] = useRecoilState(replacedActivitiesState)
    const [uniqueExplorers, setUniqueExplorers] = useRecoilState(uniqueExplorerState)
    const {data: activitiesResponse, fetch, loading} = useApi('GET_RECOMMENDATIONS', 'GET', `${location.search}&perPage=30&dataset=${pedSettings.dataset}`)

    const onClick = (index) => {
        const tabActivity = tab === 'activity'
        
        setAnimations({all: tabActivity ? animations : uniqueExplorers.activitiesByExplorer, activeIndex: index})
        history.push('/animations', {from: history.location, tab})
    }

    const remove = (index) => {
        setActivities(activities => activities.filter((a, i) => i !== index))
    }

    useEffect(() => {
        if(!activitiesResponse?.data) return

        const map = new Map()
        const explorers  = []
        const activitiesByExplorer = []

        activitiesResponse.data.forEach(activity => {
            const explorerId = activity._explorer._id
            if(!map.has(explorerId)){
                map.set(explorerId, true);  

                
                const explorersActivity = activitiesResponse.data.filter(activity => activity._explorer._id === explorerId)
                
                explorers.push({...activity._explorer, activityStartIndex: activitiesByExplorer.length});
                if(explorersActivity.length > 0) activitiesByExplorer.push(...explorersActivity)
            }
        })
        setUniqueExplorers({explorers, activitiesByExplorer: getFormattedActivities(activitiesByExplorer)})
    }, [activitiesResponse])

    useEffect(() => {
        if (!location.search) {
            return history.push('/preferences')
        }
        if (activities) return
        fetch()
    }, [fetch, location, history, activities])
    
    useEffect(() => {
        if (!activitiesResponse) return
        const formattedActivities = getFormattedActivities(activitiesResponse.data)
        const activities = activitiesResponse.data.slice(0, 10)
        setReplaceableActivities(activitiesResponse.data.slice(10, 30))
        setAnimations({all: formattedActivities.slice(0, 10), activeIndex: 0})
        setActivities(activities)
        setReplacedActivities(activities)
    }, [activitiesResponse])
    
    useEffect(() => {
        if (!replacedActivities) {
            return toggleRender(true)
        }
        setActivities(replacedActivities)
        setAnimations({all: getFormattedActivities(replacedActivities), activeIndex: 0})
        toggleRender(true)
    }, [tab])
    useEffect(() => {
        setTimeout(() => toggleFirstRender(false), 15000)
    }, [])
        

    if (activities === null || loading || !loaded) {
        return <Loading setLoaded={() => setLoaded('overlay-dark')}/>
    }

    if (!render) {
        return <></>
    }

    return (
        <div className={styles.wrapper}>
            {activities &&  animate && <Overlay light={loaded !== 'overlay-dark'}/>}
            <Box
                bg="white.100"
                position="relative"
                className={styles.step3}>
                <Flex
                    justifyContent="space-between"
                >
                    <div className={styles.headerWrapper}>
                        <Header/>
                    </div>
                       {width >= 600 &&
                        <div 
                            className={`${styles.tabsWrapper} ${styles.slideUp}`}
                            style={animate ? {
                                animationDelay: `${(firstRender ? 2.3: 0.3)}s`,
                            } : {
                                animationDelay: 0,
                                opacity: 1,
                                animation: 'none'
                                }
                            }>
                            <button 
                                onClick={() => toggleTabs('activity') || toggleAnimate(false)}
                                className={`${styles.tab} ${tab === 'activity' && styles.activeTab}` }>
                                <span>
                                    Activities
                                </span>
                            </button>
                            <button 
                                onClick={() => toggleTabs('explorer')}
                                className={`${styles.tab} ${tab === 'explorer' && styles.activeTab}` }>
                                <span>
                                    Explorers
                                </span>
                            </button>
                        </div>}

                </Flex>
                <div className={styles.dataWrapper}>
                    <div className={styles.main}>
                        <Flex
                            align="center"
                            className={styles.icons}>
                            <img
                                data-dialog="true"
                                src={pedSettings.airlineLogo ? `${CLOUDINARY_URL}/${pedSettings.airlineLogo}` : '/icons/light/ten.svg'}
                                style={animate ? {animationDelay: '.2s'} :{ animationDelay: '0'}}
                                className={animate ? styles.slideUp : ''}/>
                        </Flex>
                        <Heading
                            as="h2"
                            fontSize="h2"
                            marginY=".69em"
                            className={animate ? styles.slideUp : ''}
                            style={{animationDelay: '.5s'}}>
                            {pedSettings.resultMessage || 'Your Top Ten List'}
                        </Heading>
                        <Box
                            mb={width > 600 ? 2 : 10}
                            lineHeight="1.22em"
                            d="block"
                            as="span"
                            color="grey.300"
                            className={`${animate ? styles.slideUp : ''} ${styles.description}`}
                            style={{animationDelay: animate ? '.8s' : '0'}}>
                            {pedSettings.resultText || 'So you’re off to [city]. Amazing! Our local experts hooked up with Olivr (RAD’s personal AI brain) to curate these 10 awesome experiences just for your trip to [city nickname].'}
                        </Box>
                        {width > 600 && (
                            <SendTo
                                buttonClassName={animate ? styles.slideUp : ''}
                                buttonStyle={{animationDelay: animate ? '1.1s' : '0'}}
                                isModal={true}
                            />
                        )}
                    </div>
                    <div className={styles.cards}>
                    {/* {animate ? ' animate' : 'without animate'} */}
                        <Heading
                            as="h3"
                            style={animate ? {animationDelay: '1.1s'} : { 
                                animationDelay: '0',
                                animation: 'none',
                                opacity: 1
                            }}
                            className={`${styles.cardsBorder} ${animate ? styles.slideUp : ''}`}
                            >
                            Activities
                        </Heading>
                        {tab === 'activity' && activities.map(
                            (activity, index) => (
                                <Card
                                    removeOption={true}
                                    index={index}
                                    data={activity}
                                    onClick={onClick}
                                    getFormattedActivities={getFormattedActivities}
                                    remove={() => remove(index)}
                                    className={animate ? styles.slideUp : ''}
                                    style={animate 
                                        ? {
                                            animationDelay: `${(firstRender ? 2 + index * .3: .3 + index * .3)}s`,
                                            gridColumn: 'span 2',
                                        }
                                        : {
                                            gridColumn: 'span 2'
                                        }
                                    }
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={activity._id}/>
                            ),
                        )}
                        <Heading
                            as="h3"
                            className={styles.cardsBorder}>
                            Explorers
                        </Heading>
                        {width < 600 || tab === 'explorer' ? uniqueExplorers.explorers.map(
                            (explorer, index) => (
                                <ExplorerCard
                                    index={explorer.activityStartIndex}
                                    data={explorer}
                                    onClick={onClick}
                                    className={animate ? styles.slideUp : ''}
                                    style={animate 
                                        ? {
                                            animationDelay: `${(firstRender ? 2 + index * .3: .3 + index * .3)}s`,
                                            gridColumn: 'span 2',
                                        }
                                        : {
                                            gridColumn: 'span 2'
                                        }
                                    }
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={explorer._id}/>
                            ),
                        ) : <></>}
                    </div>
                </div>
                {width <= 600 && (
                    <SendTo delay={'false'}/>
                )}
            </Box>
        </div>
    )
}

export default Results
