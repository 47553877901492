/* eslint-disable */
import React, {useState, useEffect} from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { CLOUDINARY_URL } from '../../constants'
import styles from './index.module.scss'

const ExplorerCard = ({ 
    index,
    data, 
    style, 
    className,
    onClick = () => {},
 }) => {
    const placeholderProfile = '/images/placeholder-profile.jpg'
    const [close, setClose] = useState(false)
    const [avatar, setAvatar] = useState(`${CLOUDINARY_URL}/w_620,h_620/${data.profile_picture}`)
    const bio = data?.bio?.length > 150
        ? `${data.bio.substring(0, 150)}...`
        : data.bio

    return (
        <Box
            w="full"
            onClick={() => onClick(index)}
            overflow="hidden"
            position="relative"
            overflow="hidden"
            mb="8"
            w="full"
            className={className}
            style={style}>
            <Box
                color="black.100"
                bg="white.100"
                className={styles.card}>
                <div className={styles.imgContainer}>
                    <div
                        className={styles.imageWrapper}>
                        <img
                            key={data._id}
                            src={avatar}
                            onError={() => setAvatar(placeholderProfile)}
                            alt={data.name}
                            className={styles.img}/>
                    </div>
                </div>
                <Heading
                    as="h5"
                    data-close={close}
                    textAlign="center"
                    fontSize="md">
                    {data.full_name}
                </Heading>
                <Text 
                data-close={close}
                textAlign="center"
                >
                    {bio}
                </Text>
            </Box>
        </Box>
    )
}

export default ExplorerCard