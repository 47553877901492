/* eslint-disable */
import React from 'react'
import styles from './index.module.scss'

const Item = ({
    index,
    slide,
    slides,
    paused
}) => {
    const style = {width: `${95 / slides.length}%`}
    return (
        <div
            key={`slide-${slide.image}-${index}`}
            style={style}
            className={styles.progress}
            data-paused={paused}
            data-single={slides.length === 1}
            data-previous={slide > index}
            data-is-active={!paused && slide === index}>
                <span/>
        </div>
    )
}

export default Item
