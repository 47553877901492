/** @jsx jsx */
/* eslint-disable */
import { Fragment, useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { css, jsx } from '@emotion/core'
import {
    Heading,
    Box,
    Flex,
} from '@chakra-ui/react'
import Header from '../Header'
import { replaceableActivitiesState, activitiesState, replacedActivitiesState } from '../../atoms/state'
import { Button } from '../Theme'
import { usePedSettings } from '../../hooks'
import { KEYWORDS, CLOUDINARY_URL } from '../../constants'
import styles from './index.module.scss'

const classes = {
    slideUp: css`
        opacity: 0;
        animation: slideUp 1s forwards;
    `
}

const Preferences = () => {
    const pedSetting = usePedSettings()
    const history = useHistory()
    const loadedImages = useRef(0)
    const keywordCount = useMemo(() => window.innerWidth > 1000 ? 9 : 8, [])
    const setReplaceableActivities = useSetRecoilState(replaceableActivitiesState)
    const setActivities = useSetRecoilState(activitiesState)
    const setReplacedActivities = useSetRecoilState(replacedActivitiesState)
    const [mounted, setMounted] = useState()
    const [step, setStep] = useState(1)
    const [selected, setSelected] = useState(new Set())
    const [thirdSelected, setThirdSelected] = useState(new Set())
    const [imageCached, setImageCached] = useState(false)

    const keywords = useMemo(
        () => {
            if (step !== 3) {
                return KEYWORDS[step - 1].slice(0, keywordCount)
            } else {
                return [...KEYWORDS[0], ...KEYWORDS[1]]
                    .filter(keyword => !selected.has(keyword))
                    .slice(0, keywordCount)
            }
        },
        [step, selected]
    )
    

    const cacheController = useCallback(() => {
        loadedImages.current++
        if (loadedImages.current === keywordCount){
            loadedImages.current = 0
            return setImageCached(true)
        }

    }, [keywordCount])
   
    const toggle = useCallback(
        (keyword, step) => (e) => {
            e.preventDefault()
            if (step === 3) {
                return setThirdSelected(
                    (prevSelected) => {
                        if (prevSelected.has(keyword)) {
                            prevSelected.delete(keyword)
                        } else {
                            prevSelected.add(keyword)
                        }
    
                        return new Set([...prevSelected])
                    },
                )
            }
            setSelected(
                (prevSelected) => {
                    if (prevSelected.has(keyword)) {
                        prevSelected.delete(keyword)
                    } else {
                        prevSelected.add(keyword)
                    }

                    return new Set([...prevSelected])
                },
            )
        },
        [setSelected],
    )

    const next = useCallback(
        () => {
            const keywords = [...selected, ...thirdSelected]

            if (!keywords.length) {
                return alert('Please select preferences to proceed!')
            }

            if (step === 1) {
                return setStep(step + 1)
            }

            if (step === 2) {
                return setStep(step + 1)
            }

            history.push(`/results?keywords=${keywords.join(',')}`)
        },
        [step, setStep, selected, history],
    )

    useEffect(() => {
        setTimeout(() => {
            setMounted(true)
        }, 1000)
    }, [setMounted])

    useEffect(() => {
        setImageCached(false)
        window.scrollTo({
            top: 0
        })
    }, [step])

    useEffect(() => {
        setReplaceableActivities(null)
        setActivities(null)
        setReplacedActivities(null)
    }, [])

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.coverWrapper}>
                {mounted && (
                    <Box
                        className={styles.step2}
                        bg="white.100">
                            <div className={styles.keywordsWrapper}>
                                <div className={styles.mainInfo}>
                                    <div>
                                        <Header
                                            showSteps={true}
                                            active={step}
                                            onChange={setStep}/>
                                    </div>
                                    <Heading
                                        as="h2"
                                        fontSize="h2"
                                        fontWeight="normal"
                                        my={8}
                                        css={classes.slideUp}>
                                        {step === 1 && (
                                            <Fragment>
                                                {pedSetting.question1 || 'Which photos do you envision for your trip?'}
                                                <span
                                                    role="img"
                                                    aria-label="camera">
                                                    📸
                                                </span>
                                            </Fragment>
                                        )}
                                        {step === 2 && (
                                            <Fragment>
                                                {pedSetting.question2 || 'Which photos do you identify with?'}
                                                <span
                                                    role="img"
                                                    aria-label="camera">
                                                    🙂
                                                </span>
                                            </Fragment>
                                        )}
                                        {step === 3 && (
                                            <Fragment>
                                                {pedSetting.question3 || 'What’s your perfect morning routine on vacation?'}
                                                <span
                                                    role="img"
                                                    aria-label="camera">
                                                    ☀️
                                                </span>
                                            </Fragment>
                                        )}
                                    </Heading>
                                </div>
                                <div 
                                    className={styles.keywords}>
                                    {keywords.map(
                                        (keyword, index) => (
                                            <span
                                                style={!imageCached ? {display:'none'} : {animationDelay: `${0.2 * index}s`}}
                                                onClick={toggle(keyword, step)}
                                                className={styles.keywordWrapper}
                                                data-odd={index % 2 !== 0}
                                                css={classes.slideUp}
                                                key={`keyword-${keyword}-index-${index}-${step}`}>
                                                <img
                                                    onLoad={cacheController}
                                                    src={pedSetting.keywords && pedSetting.keywords[keyword] ? `${CLOUDINARY_URL}/${pedSetting.keywords[keyword]}` : `/images/${keyword}.jpg`}
                                                    alt={keyword}
                                                    style={{border: '1px solid #ffffff'}}
                                                />
                                                <span
                                                    className={styles.selected}
                                                    data-selected={step !== 3 ? selected.has(keyword) : thirdSelected.has(keyword)}>
                                                    {keyword}
                                                </span>
                                            </span>
                                        )
                                    )}
                                </div>
                            </div>
                            <Flex className={styles.footer}>
                                <Button
                                    className={styles.button}
                                    onClick={next}>
                                    Next
                                </Button>
                            </Flex>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Preferences
