/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Link as ReactLink } from 'react-router-dom'
import { Box, Link } from '@chakra-ui/react'
import { Icon } from '../Theme'
import styles from './index.module.scss'

const classes = {
    steps: css`
        opacity: 0;
        animation: slideDown 1s forwards;
    `,
}

const Header = ({
    showSteps = false,
    logoDark = true,
    active,
    alignment = ['center', 'flex-start'],
    onChange = () => {},
}) => (
    <Box
        pt="5"
        display="flex"
        position="relative"
        zIndex="2"
        flexDirection="column">
        <Box
            display="flex"
            justifyContent={alignment}>
            <Link
                as={ReactLink}
                to="/">
                <Icon
                    type={`${logoDark ? 'dark' : 'light'}/logo`}
                    className={styles.logo}/>
            </Link>
        </Box>
        {showSteps && (
            <Box
                data-active={active}
                className={styles.step}
                css={classes.steps}>
                <Box
                    as="span"
                    color="grey.100"
                    data-active={active === 1}
                    onClick={() => onChange(1)}>
                    Step 1
                </Box>
                <Box
                    marginX={2}
                    as="i"
                    color="grey.100">
                    {'>'}
                </Box>
                <Box
                    as="span"
                    color="grey.100"
                    data-active={active === 2}
                    onClick={() => onChange(2)}>
                    Step 2
                </Box>
                <Box
                    marginX={2}
                    as="i"
                    color="grey.100">
                    {'>'}
                </Box>
                <Box
                    as="span"
                    color="grey.100"
                    data-active={active === 3}
                    onClick={() => onChange(3)}>
                    Step 3
                </Box>
            </Box>
        )}
    </Box>
)

export default Header
