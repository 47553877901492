import React from 'react'
import styles from './index.module.scss'

const Button = ({
    children,
    className,
    transparent,
    onClick = () => {},
    ...props
}) => (
    <button
        data-transparent={transparent}
        className={`${styles.button} ${className}`}
        onClick={onClick}
        type="button"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}>
        {children}
    </button>
)

export default Button
