/* eslint-disable no-underscore-dangle */
import React, { useEffect, useCallback, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Flex, Box, Heading } from '@chakra-ui/react'
import Wrapper from './Wrapper'
import ExplorerAvatar from './ExplorerAvatar'
import { slideAnimationState } from '../../../atoms/state'
import { CLOUDINARY_URL } from '../../../constants'
import styles from './index.module.scss'
import FormattedDescription from './FormattedDescription'

const ANIMATION_TYPES = ['zoomOutBottom', 'zoomInTopLeft', 'zoomInBottomRight', 'zoomInBottom', 'zoomInTopRight', 'zoomInBottomLeft', 'zoomOutCenter', 'zoomInBottom', 'zoomInTop', 'zoomInCenter', 'zoomInTopLeft', 'zoomInBottomRight', 'zoomInTopRight', 'zoomOutTop', 'zoomInBottomLeft', 'zoomInBottom', 'zoomInTop', 'zoomInCenter', 'zoomOutTop']

const Animation = ({
    slide,
    index,
    onEnd,
    swipe: onSwipe = () => {},
    onClose = () => {},
    onNext = () => {},
    onPrev = () => {},
}) => {
    const [slideAnimation, setSlideAnimation] = useRecoilState(slideAnimationState)
    const [hideTextAnimations, setHideTextAnimations] = useState(false)
    const animationItemStyle = {
        backgroundImage: `url('${CLOUDINARY_URL}/w_1920,f_auto/${slide.image}')`,
    }

    const activitySwipe = useCallback(
        (direction) => {
            setSlideAnimation({ image: `${CLOUDINARY_URL}/w_1920,f_auto/${slide.image}}`, direction, animationType: ANIMATION_TYPES[index].replace('zoom', 'zoomed') })
            onSwipe(direction)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSlideAnimation, onSwipe, slide],
    )

    const close = useCallback(
        () => {
            setHideTextAnimations(true)
            onClose()
        },
        [setHideTextAnimations, onClose],
    )

    useEffect(() => {
        if (hideTextAnimations) setHideTextAnimations(false)
        const timeout = setTimeout(() => {
            onEnd()
        }, 6000)

        const textAnimationsTimeout = setTimeout(() => {
            if (!slide.nextSlide) return
            setHideTextAnimations(true)
        }, 5200)

        return () => {
            clearTimeout(timeout)
            clearTimeout(textAnimationsTimeout)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slide])

    return (
        <Wrapper
            onNext={onNext}
            onPrev={onPrev}
            onClose={close}
            slide={slide}
            activitySwipe={activitySwipe}>
            <div
                key={`image-${slide.image}`}
                className={`${styles.item} ${styles[ANIMATION_TYPES[index]]}`}
                data-has-next={!!slide.nextSlide}
                style={animationItemStyle}/>
            {slide.nextSlide && (
                <div
                    key={`image-${slide.image}-next-slide`}
                    className={`${styles.nextSlide} ${styles[ANIMATION_TYPES[index]]}`}
                    style={{ backgroundImage: `url("${CLOUDINARY_URL}/w_1920,f_auto/${slide.nextSlide}")` }}/>
            )}
            {slideAnimation && (
                <div
                    key={`image-${slideAnimation.image}`}
                    className={`${styles.item} ${styles[slideAnimation.animationType]} ${styles[`slideTo${slideAnimation.direction}`]}`}
                    style={{ backgroundImage: `url(${CLOUDINARY_URL}/w_1920,f_auto/${slideAnimation.image})` }}/>
            )}
            <div
                className={styles.container}>
                <Flex
                    key={`slide-image-${slide.image}-name`}
                    align="center"
                    alignItems="center"
                    style={{ marginBottom: '20vh', minHeight: 50 }}
                    className={`${hideTextAnimations ? styles.hideToTop : ''} ${styles.headingInfo}`}>
                    <ExplorerAvatar
                        profilePicture={slide._explorer.profile_picture}/>
                    <Flex
                        className={styles.explorerNameWrapper}
                        direction="column">
                        <Heading
                            as="h5"
                            fontSize="md">
                            {slide.name}
                        </Heading>
                        <Box
                            as="span"
                            className={styles.explorerName}>
                            {slide._explorer.full_name}
                        </Box>
                    </Flex>
                </Flex>
                <FormattedDescription
                    slide={slide}
                    hideTextAnimations={hideTextAnimations}/>
            </div>
        </Wrapper>
    )
}

const compareProps = (oldProps, nextProps) => (oldProps.closing !== nextProps.closing)

export default React.memo(Animation, compareProps)
