/** @jsx jsx */
import React from 'react'
import Typing from 'react-typing-animation'
import { Text } from '@chakra-ui/react'
import { css, jsx } from '@emotion/core'
import styles from './index.module.scss'

const classes = {
    fadeOut: css`
        animation: fadeOut 1s forwards;
    `,
}

const FormattedDescription = ({
    slide,
    hideTextAnimations,
}) => {
    const text = slide.description
    const description = text.substring(0, 86).split(' ')
    return (description.length > 1 ? (
        <Typing
            key={`slide-image-${slide.image}-animation`}
            speed={20}
            startDelay={500}
            css={hideTextAnimations ? classes.fadeOut : ''}>
            <Text className={styles.description}>
                <p>
                    {description.slice(0, 4).join(' ') || <p/>}
                    <span className={styles.yellowText}>
                        &nbsp;
                        {description.slice(4, 6).join(' ') || <p/>}
                        &nbsp;
                    </span>
                    {description.slice(6, description.length).join(' ') || <p/>}
                    {text.length > 85 ? '...'
                        : <p/>}
                </p>
            </Text>
        </Typing>
    ) : <React.Fragment/>
    )
}

export default FormattedDescription
