import { useCallback } from 'react'
import axios from 'axios'
import { useFetcher } from '.'

const ENDPOINTS = {
    GET_RECOMMENDATIONS: process.env.REACT_APP_RECOMMENDATIONS_API,
    REACT_APP_ACTIVITY_API: process.env.REACT_APP_ACTIVITY_API,
    REACT_APP_FAVORITES_API: process.env.REACT_APP_FAVORITES_API,
    REACT_APP_SEND_EMAIL_API: process.env.REACT_APP_SEND_EMAIL_API,
}

// TODO: convert to react-query instead, also remove useFetcher in that case
const useApi = (
    api,
    method,
    url = '',
    options,
) => {
    const {
        data,
        loading,
        error,
        fetcher,
    } = useFetcher()

    const fetch = useCallback(
        (body) => {
            fetcher(
                axios({
                    url: `${ENDPOINTS[api]}${url}`,
                    method,
                    data: body ?? null,
                    options: options ?? {},
                }),
            )
        },
        [fetcher, api, url, method, options],
    )

    return {
        data,
        fetch,
        loading,
        error,
    }
}

export default useApi
