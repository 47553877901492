import React from 'react'
import styles from './index.module.scss'

const Layer = (style) => (
    <div
        style={style}
        className={styles.layer}/>
)

export default Layer
