import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ROUTES } from '../components/App/Component'

const withRouter = (Component) => ({
    ...props
}) => {
    const routes = ROUTES.map(route => route.path)
    const subdomain = window.location.pathname.split('/')[1]

    const isSubdomain = !routes.includes(`/${subdomain}`)
    return (
        <BrowserRouter basename={isSubdomain ? subdomain : null}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props}/>
        </BrowserRouter>
    )
}

export default withRouter
