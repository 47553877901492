import React from 'react'
import { RecoilRoot } from 'recoil'
import { ChakraProvider } from '@chakra-ui/react'
import { Route } from 'react-router-dom'
import theme from '../Theme'
import '../../scss/_template.scss'
import Welcome from '../Welcome'
import BackgroundProvider from '../../providers/backgroundProvider'
import TravelingFor from '../TravelingFor'
import Preferences from '../Preferences'
import Results from '../Results'
import Animations from '../Animations'
import Favorites from '../Favorites'
import styles from './index.module.scss'

export const ROUTES = [
    { path: '/', component: Welcome },
    { path: '/traveling-for', component: TravelingFor },
    { path: '/preferences', component: Preferences },
    { path: '/results', component: Results },
    { path: '/animations', component: Animations },
    { path: '/favorites', component: Favorites },
]

const App = () => (
    <RecoilRoot>
        <ChakraProvider theme={theme}>
            <BackgroundProvider>
                <div className={styles.app}>
                    <main className={styles.main}>
                        {ROUTES.map((route) => (
                            <Route
                                key={`route-${route.path}`}
                                path={route.path}
                                exact
                                component={route.component}/>
                        ))}
                    </main>
                </div>
            </BackgroundProvider>
        </ChakraProvider>
    </RecoilRoot>
)

export default App

