/* eslint-disable */
import React from 'react'
import { Button } from '../../../Theme'
import styles from './index.module.scss'

const SendMeButton = ({
    className,
    style,
    onClick
}) => {
    return (
        <Button
            style={style}
            className={`${styles.button} ${className}`}
            onClick={onClick}>
            Send my Top 10
        </Button>
    )
}

export default SendMeButton
