/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import Wrapper from './Wrapper'
import { Icon, Input, Button } from '../../Theme'
import SendMeButton from './SendMeButton'
import axios from 'axios'
import styles from './index.module.scss'

const SendTo = ({
    isModal,
    buttonStyle,
    buttonClassName,
    delay
}) => {
    const [visible, setVisible] = useState(false)
    const [active, setActive] = useState('mobile')
    const [value, setValue] = useState('')

    const close = useCallback(
        (thanks) => {
            setValue('')
            setVisible('hide')
            setTimeout(() => {
                setVisible('shrink')
            }, 1500)
            setTimeout(() => {
                if (thanks) {
                    setTimeout(() => {
                        setVisible('hidden')
                    }, 2700)
                    return setVisible('thanks')
                }
                setVisible(false)
            }, 1800)
        },
        [setVisible, setValue]
    )

    const onClick = useCallback(
        () => {
            if (!visible) {
                setVisible('animate')
                return setTimeout(() => {
                    console.log('setting visible true')
                    setVisible(true)
                }, 500)
            }
            if (!value) return alert('Invalid value')
            axios
                .get(`https://te81b1jk07.execute-api.us-east-1.amazonaws.com/default/sendEmail-dev?email=${value}&listLink=${window.location.href}`)
                .then(console.log)
                .catch(console.error)
            close('thanks')
        },
        [value, visible, setVisible]
    )

    const onActiveChange = useCallback(
        (newActive) => {
            setValue('')
            setActive(newActive)
        },
        [setValue, setActive]
    )

    useEffect(() => {
        setVisible(false)
    }, [])

    if (visible === 'hidden') {
        return <></>
    }

    return (
        <Wrapper
            delay={delay}
            isModal={isModal}
            buttonStyle={buttonStyle}
            buttonClassName={buttonClassName}
            onClose={close}
            visible={visible}
            onClick={onClick}>
            <div style={{display: "none"}}>
                <img src="/icons/light/heart.svg"/>
                <img src="/icons/dark/close.svg"/>
            </div>
            {visible && visible !== 'animate' && visible !== 'shrink' && visible !== 'thanks' && (
                <Box
                    pt="8"
                    className={styles.modal}>
                    <Icon
                        className={`${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        style={{opacity: visible === 'hide' ? 1 : 0, animationDelay: visible === 'hide' ? '0s' : '500ms'}}
                        type="light/heart"
                        width={47}
                    />
                    <Box
                        as="h5"
                        mt="8"
                        mb="8"
                        d="block"
                        className={`${styles.title} ${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        style={{opacity: visible === 'hide' ? 1 : 0, animationDelay: visible === 'hide' ? '200ms' : '700ms'}}>
                        My Favorites
                    </Box>
                    <Button
                        transparent={true}
                        className={`${styles.close} ${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        onClick={() => close()}>
                        <Icon
                            type="dark/close"
                            width={30}/>
                    </Button>
                    <Box
                        as="span"
                        mb="4"
                        d="block"
                        className={`${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        style={{opacity: visible === 'hide' ? 1 : 0, animationDelay: visible === 'hide' ? '400ms' : '900ms'}}>
                        Get this list sent to your phone or email.
                    </Box>
                    <div
                        className={`${styles.type} ${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        style={{opacity: visible === 'hide' ? 1 : 0, animationDelay: visible === 'hide' ? '600ms' : '1100ms'}}>
                        <span
                            data-active={active === 'mobile'}
                            onClick={() => onActiveChange('mobile')}>
                            Mobile
                        </span>
                        <div/>
                        <span
                            data-active={active === 'email'}
                            onClick={() => onActiveChange('email')}>
                            Email
                        </span>
                    </div>
                    <Input
                        type={active === 'mobile' ? 'number' : 'email'}
                        className={`${styles.input} ${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        style={{opacity: visible === 'hide' ? 1 : 0, animationDelay: visible === 'hide' ? '800ms' : '1300ms'}}
                    />
                    <Box
                        as="span"
                        d="block"
                        mt="2"
                        mb="3"
                        fontSize="sm"
                        className={`${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                        style={{animationDelay: visible === 'hide' ? '1000ms' : '1500ms', opacity: visible === 'hide' ? 1 : 0}}>
                        {active === 'mobile' ? 'Country code - Your Number' : 'Enter Your Email Above'}
                    </Box>
                    {isModal && (
                        <SendMeButton
                            className={`${visible === 'hide' ? styles.slideUpHide : styles.slideUp}`}
                            style={{animationDelay: visible === 'hide' ? '1200ms' : '1700ms', opacity: visible === 'hide' ? 1 : 0}}
                            onClick={onClick}
                        />
                    )}
                </Box>
            )}
            {visible === 'thanks' && (
                <Flex
                    direction="column"
                    alignItems="center"
                    py="3"
                    className={styles.thanks}>
                        <Icon
                            type="light/heart"
                            className={styles.slideUp}
                            style={{opacity: 0, animationDelay: '200ms'}}
                            width={55}/>
                        <Box
                            as="span"
                            mt="3"
                            className={styles.slideUp}
                            style={{opacity: 0, animationDelay: '400ms'}}>
                            Thank you
                        </Box>
                </Flex>
            )}
        </Wrapper>
    )
}

export default SendTo
