import { extendTheme } from '@chakra-ui/react'
import Icon from './Icon'
import Button from './Button'
import Input from './Input'

const theme = extendTheme({
    colors: {
        white: {
            100: '#ffffff',
            200: '#fffdfd',
        },
        black: {
            100: '#000000',
        },
        grey: {
            100: '#C6C6C6',
            200: '#bbbbbb',
            300: '#a8a8a8',
            400: '#747474',
        },
        yellow: {
            100: '#E6E3CA',
        },
    },
    fontSizes: {
        h1: '3.75rem',
        h2: '2.25rem',
        h3: '1.875rem',
    },
    fonts: {
        heading: '"Reader", sans-serif',
        body: '"Reader", sans-serif',
        mono: '"Reader", monospace',
    }
})

export {
    Icon,
    Button,
    Input,
}

export default theme
