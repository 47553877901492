import React from 'react'
import { Box } from '@chakra-ui/react'
import { Button } from '../../../Theme'
import styles from './index.module.scss'

const ActionButtons = ({
    closing,
    animate,
    onMapOpen = () => {},
}) => (
    <Box
        style={{ marginTop: 20 }}
        color="black.100"
        data-hide={closing}
        className={`${styles.buttons} ${animate ? styles.animatedShow : ''}`}>
        <Button onClick={onMapOpen}>
            View Map
        </Button>
        <Button>
            Book Now
        </Button>
    </Box>
)

export default ActionButtons
