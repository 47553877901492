import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react'
import { useSetRecoilState } from 'recoil'
import Animation from '../Animation/index'
import Progress from '../Progress/index'
import LoadImages from './LoadImages'
import NavigationButtons from './NavigationButtons/index'
import ActionButtons from './ActionButtons'
import Loading from '../Loading'
import { slideAnimationState } from '../../../atoms/state'

const Activity = ({
    slides,
    slideActiveIndex,
    nextSlides,
    onEnd = () => {},
    onPrev: onActivityPrev = () => {},
    swipe = () => {},
    openMap = () => {},
    onClose = () => {},
}) => {
    const [slide, setSlide] = useState(slideActiveIndex === null ? 0 : slideActiveIndex)
    const [loading, setLoading] = useState(slides.length > 1)
    const [closing, setClosing] = useState()
    const setSlideAnimation = useSetRecoilState(slideAnimationState)
    const firstRender = useRef(true)

    const close = () => {
        setClosing(true)
        setTimeout(() => {
            onClose()
        }, 1500)
    }

    const onSwipe = (direction) => {
        setSlide(0)
        setSlideAnimation({ image: slides[slide].image, direction: 'Left' })
        swipe(direction)
    }

    const onNext = useCallback(
        () => {
            if (slide + 1 === slides.length) {
                setSlideAnimation({ image: slides[slide].image, direction: 'Left' })
                setSlide(0)
                return onEnd()
            }
            setSlide(slide + 1)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [slide, onEnd, setSlide],
    )

    const onPrev = useCallback(
        () => {
            if (slide === 0) {
                setSlideAnimation({ image: slides[slide].image, direction: 'Right' })
                setSlide(0)
                return onActivityPrev()
            }
            setSlide(slide - 1)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [slide, setSlide, onActivityPrev],
    )

    useEffect(() => {
        if (slides.length < 2) return
        setLoading(true)
    }, [slides])

    useEffect(() => {
        if (loading) return
        firstRender.current = false
    }, [loading])

    return (
        <>
            {nextSlides && !loading && (
                <LoadImages slides={nextSlides}/>
            )}
            {loading ? (
                <>
                    <Loading onClose={onClose}/>
                    <LoadImages
                        slides={slides}
                        setLoading={setLoading}/>
                </>
            ) : (
                <>
                    <Animation
                        index={slide}
                        slide={slides[slide]}
                        onEnd={onNext}
                        onNext={onNext}
                        closing={closing}
                        onPrev={onPrev}
                        onClose={close}
                        swipe={onSwipe}/>
                    <NavigationButtons
                        closing={closing}
                        slide={slide}
                        allSlides={slides.length}
                        onPrev={onPrev}
                        onNext={onNext}/>
                </>
            )}
            <Progress
                paused={loading}
                slide={slide}
                slides={slides}/>
            {!loading && (
                <ActionButtons
                    animate={firstRender.current}
                    closing={closing}
                    onMapOpen={() => openMap({ slide })}/>
            )}
        </>
    )
}

export default Activity
