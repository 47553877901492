import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react'
import { useSetRecoilState } from 'recoil'
import Hammer from 'hammerjs'
import { slideAnimationState } from '../../../../atoms/state'
import { Button, Icon } from '../../../Theme'
import styles from './index.module.scss'

const Wrapper = ({
    children,
    slide,
    onNext,
    onPrev,
    activitySwipe,
    onClose = () => {},
}) => {
    const animationOverlay = useRef()
    const setSlideAnimation = useSetRecoilState(slideAnimationState)
    const [closing, setClosing] = useState()
    const height = window.innerHeight

    const close = useCallback(
        () => {
            onClose()
            setTimeout(() => {
                setClosing(true)
                setSlideAnimation(null)
            }, 500)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setClosing, onClose],
    )

    useEffect(() => {
        if (!animationOverlay.current) return
        const overlay = animationOverlay.current
        const hammertime = new Hammer(overlay)

        hammertime.get('swipe').set({ direction: Hammer.DIRECTION_ALL })
        hammertime.on('tap', (e) => {
            e.preventDefault()
            const { clientX } = e.srcEvent
            const width = overlay.offsetWidth
            const center = Math.round(width / 2)
            if (clientX > center) return onNext()
            return onPrev()
        })

        hammertime.on('swipeleft', (e) => {
            e.preventDefault()
            return activitySwipe('Left')
        })

        hammertime.on('swiperight', (e) => {
            e.preventDefault()
            return activitySwipe('Right')
        })

        hammertime.on('swipedown', (e) => {
            e.preventDefault()
            const keep = Math.abs(e.deltaY) < 40 || Math.abs(e.velocityY) < 0.25
            if (keep) return
            close()
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationOverlay, slide])

    return (
        <div
            className={styles.wrapper}
            style={{ height }}>
            <div
                className={styles.wrapperAnimate}
                data-is-closing={closing}>
                <Button
                    transparent
                    className={styles.close}
                    onClick={close}>
                    <Icon type="light/close"/>
                </Button>
                <div
                    key={slide.image}
                    ref={animationOverlay}
                    className={styles.overlay}/>
                {children}
            </div>
        </div>
    )
}

export default Wrapper
