import React, { useCallback, useState } from 'react'
import { CLOUDINARY_URL } from '../../../constants'

const LoadImages = ({
    slides,
    setLoading = () => {},
}) => {
    const [loadedCount, setLoadedCount] = useState(0)

    const onLoad = useCallback(
        () => {
            if (loadedCount + 1 === slides.length) return setLoading(false)
            // eslint-disable-next-line no-shadow
            setLoadedCount((loadedCount) => loadedCount + 1)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadedCount, setLoadedCount, slides],
    )

    return (
        <>
            {slides.map(
                (slide) => (
                    <img
                        key={`slide-loading-image-${slide.image}`}
                        src={`${CLOUDINARY_URL}/w_1920,f_auto/${slide.image}`}
                        alt={slide.name}
                        className="d-none"
                        onLoad={onLoad}/>
                ),
            )}
        </>
    )
}

export default LoadImages
