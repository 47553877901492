import React from 'react'

const Icon = ({
    className,
    style,
    type,
    width,
    height,
}) => (
    <img
        data-dialog="true"
        style={style}
        src={`/icons/${type}.svg`}
        alt={`icon-${type}`}
        width={width}
        height={height}
        className={className}/>
)

export default Icon
