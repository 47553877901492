/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { CLOUDINARY_URL } from '../../../constants'

function Pin ({
    data,
    scrollPosition,
    viewport,
    currentLocation = null,
    activity,
    calculateDistance,
    updateDuration = () => {},
    loaded = () => {},
}) {
    const pin = useRef(null)
    const [active, setActive] = useState(false)
    const element = data._id ? document.querySelector(`.side-activities .activity-container.activity-${data._id}`) : null

    const getFormatedName = (slideName) => {
        let name = slideName
        if (name.length > 17) {
            name = name.substring(0, 17) + ' ...'
        }
        return name
    }

    useEffect(() => {
        if (!element || !viewport || !scrollPosition || !scrollable) {
            setActive(true)
            return
        }

        let isActive = false

        if (viewport.width > 540) {
            isActive = scrollPosition.y - viewport.height > element.offsetTop && scrollPosition.y - viewport.height < element.offsetTop + element.offsetHeight

            if (isActive) {
                setActive(true)
            } else {
                setActive(false)
            }
        } else {
            const mapPin = document.querySelector(`.mapboxgl-marker .pin.pin-${data._id} .marker`)

            if (!mapPin) {
                return
            }

            isActive = scrollPosition.x > pin.current.offsetLeft - 100 && scrollPosition.x < pin.current.offsetLeft + pin.current.offsetWidth - 60

            if (isActive) {
                mapPin.style.background = '#00f'
            } else {
                mapPin.style.background = '#000'
            }
        }

        if (isActive && currentLocation) {
            const miles = Math.round(calculateDistance(
                [currentLocation.coordinates[0], currentLocation.coordinates[1]],
                [data.loc.coordinates[0], data.loc.coordinates[1]]
            ))

            document.querySelectorAll(`.pin.pin-${data._id} .distance`).forEach(pin => {
                pin.innerHTML = `${miles} Miles from ${currentLocation.name === 'Your Location' ? 'you' : currentLocation.name}`
            })

            updateDuration(data.loc.coordinates)
        }

        const marker = document.querySelector(`.activity-marker-${data._id}`)
        if (marker) {
            if (isActive) {
                marker.style.zIndex = 100
            } else {
                marker.style.zIndex = 3
            }
        }
    }, [scrollPosition, viewport])

    useEffect(() => {
        loaded()
    }, [])

    return (
        <div
            className={`pin ${data ? 'pin-' + data._id : ''}`}
            data-bottom={`${currentLocation ? currentLocation.coordinates[1] > data.loc.coordinates[1] : false}`}
            ref={pin}>
                <div
                    className="marker"
                    style={{ background: !data._id || active ? '#00f' : '#000' }}/>
                {data && (
                    <div>
                        {data._id ? (
                            <div className="title">
                                <h5>{getFormatedName(data.name)}</h5>
                                <svg viewBox="0 0 20 20">
                                    <path d="M0,0 L20,0 L10,20"/>
                                </svg>
                            </div>
                        ) : (
                            <div className="title">
                                { data }
                                <svg viewBox="0 0 20 20">
                                    <path d="M0,0 L20,0 L10,20"/>
                                </svg>
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}

export default Pin