/* eslint-disable */
import React from 'react'
import Item from './Item'
import styles from './index.module.scss'

const Progress = ({
    slide: slideIndex,
    slides,
    paused
}) => {
    return (
        <div
            className={styles.progressWrapper}
            data-single={slides.length === 1}>
            {slides
                .filter(slide => !slide.slideAnimation)
                .map(
                    (slide, index) => (
                        <Item
                            key={`slide-${slide.image}-${index}-progress`}
                            paused={paused}
                            index={index}
                            slides={slides}
                            slide={slideIndex}
                        />
                    )
                )
            }
        </div>
    )
}

export default Progress
