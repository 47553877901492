/** @jsx jsx */
/* eslint-disable */
import { useEffect, useState } from 'react'
import ReactMapboxGl from 'react-mapbox-gl'
import { LngLatBounds } from 'mapbox-gl'
import { useSetRecoilState } from 'recoil'
import MarkersOverlay from './MarkersOverlay'
import { Flex, Heading } from '@chakra-ui/react'
import { css, jsx } from '@emotion/core'
import { Icon, Button } from '../../Theme'
import { MAPBOX_ACCESS_TOKEN } from '../../../constants'
import { slideAnimationState } from '../../../atoms/state'
import http from '../../../http'
import styles from './index.module.scss'
import './index.scss'

const classes = {
    appearToTop: css`
        animation: appearToTop 500ms forwards;
    `,
    hideToBottom: css`
        animation: hideToBottom 500ms forwards;
    `,
}

const profiles = [
    { name: 'driving', icon: '/icons/dark/driving.svg', alt: 'driving-icon' },
    { name: 'cycling', icon: '/icons/dark/cycling.svg', alt: 'cycling-icon' },
    { name: 'walking', icon: '/icons/dark/walking.svg', alt: 'walking-icon' }
]

function MapBox ({
    activities,
    showRoute,
    cityAlias,
    selectedActivity: sa = {},
    height = '100%',
    width = '100%',
    close = () => {},
}) {
    const setSlideAnimation = useSetRecoilState(slideAnimationState)
    const accessToken = 'pk.eyJ1IjoicmlsZXlqYW1lc21pbGhlbSIsImEiOiJjaXVvbDZwYncwMXAzMnlxbWo1dWc1d3p5In0.WZSAaZ_og6vEJuyhwuxzDg'
    const [hide, setHide] = useState()
    const [currentLocation, setCurrentLocation] = useState(null)
    const [bounds, setBounds] = useState(null)
    const [selectedActivity, setSelectedActivity] = useState(sa)
    const [geometries, setGeometries] = useState([])
    const [details, setDetails] = useState([])
    const [loading, loaded] = useState(true)
    const innerHeight = window.innerHeight
    const onClose = () => {
        setHide(true)
        setTimeout(() => {
            close()
        }, 1000)
    }


    const calculateBounds = (coordinates) => {
        const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new LngLatBounds(coordinates[0], coordinates[0]))

        return Object.values(bounds).map(coord => Object.values(coord))
    }

    const calculateDistance = ([lon1, lat1], [lon2, lat2]) => {
        const p = 0.017453292519943295
        const c = Math.cos
        const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) / 2
    
        return 12742 * Math.asin(Math.sqrt(a))
    }

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(({ coords }) => {
                    return resolve(coords)
                }, ({ code, message }) => {
                    return reject({
                        ...new Error(message),
                        name: 'Location Rejected',
                        code
                    })
                })
            } else {
                return reject({
                    ...new Error('browser not supported'),
                    name: 'Not Supported'
                })
            }
        })
    }

    const getCurrentLocation = async () => {
        let coordinates = [sa.airport.longitude, sa.airport.latitude]
        let name = sa.airport.name

        try {
            const userLocation = await getUserLocation()
            const distance = calculateDistance([userLocation.latitude, userLocation.longitude], [selectedActivity.loc.coordinates[1], selectedActivity.loc.coordinates[0]])
            if (distance < 50) {
                coordinates = [userLocation.longitude, userLocation.latitude]
                name = 'Your Location'
            }
        } catch (e) {
            console.log(e)
            console.log('not able to get user location')
        }
        setCurrentLocation({ coordinates, name })
    }

    const getDuration = (detail) => {
        const mins = Math.round(detail / 60)
        let duration = ''

        if (mins > 60) {
            duration = (mins / 60).toFixed(2) + ' Hours'
        } else if (mins > 0) {
            duration = mins + ' Min'
        }

        return duration
    }

    const getProfiles = async (i, newGeometries, newDetails) => {
        return new Promise (async (resolve, reject) => {
            const selectedActivity = activities[i]
            let geometries = newGeometries
            let details = newDetails
            if (!selectedActivity || !selectedActivity.loc || !currentLocation) {
                if (geometries.length > 0) setGeometries([...geometries])
                if (details.length > 0) setDetails([...details])
                loaded(false)
                return reject('')
            }
            
            try {
                const response = await Promise.all(profiles.map(profile => http.get(`https://api.mapbox.com/directions/v5/mapbox/${profile.name}/${currentLocation.coordinates.join(',')};${selectedActivity.loc.coordinates.join(',')}`, {
                    params: {
                        access_token: MAPBOX_ACCESS_TOKEN,
                        geometries: 'geojson'
                    }
                })))

                if (i === 0) {
                    setBounds(calculateBounds(...response.map(route => route.data.routes[0].geometry.coordinates).filter(v => v)))
                }

                details = [
                    ...details,
                    {
                        id: selectedActivity._id,
                        data: response.map(geometry => {
                            const route = geometry.data.routes[0]
                            const miles = Math.round(calculateDistance(
                                [currentLocation.coordinates[0], currentLocation.coordinates[1]],
                                [selectedActivity.loc.coordinates[0], selectedActivity.loc.coordinates[1]]
                            ))
        
                            document.querySelectorAll(`.pin.pin-${selectedActivity._id} .distance`).forEach(pin => {
                                pin.innerHTML = `${miles} Miles from ${currentLocation.name === 'Your Location' ? 'you' : currentLocation.name}`
                            })
        
                            return {
                                duration: getDuration(route.duration)
                            }
                        })
                    }
                ]

                geometries = [
                    ...geometries,
                    {
                        id: selectedActivity._id,
                        data: response.map(geometry => {
                            const route = geometry.data.routes[0]
                            return {
                                type: 'Feature',
                                geometry: route.geometry
                            }
                        })
                    }
                ]

                return resolve(getProfiles(i + 1, geometries, details))
            } catch (e) {
                console.log('Network error')
                console.log(e.message || e)
                return reject(e)
            }
        })
    }

    useEffect(() => {
        if (!selectedActivity.loc) return
        getCurrentLocation()
    }, [selectedActivity])
    
    useEffect(() => {
        if (currentLocation !== null) {
            getProfiles(0, [], [])
            setTimeout(() => {
                loaded(false)
            }, 500)
        }
    }, [currentLocation])

    useEffect(() => {
        setSelectedActivity(sa)
    }, [sa])

    useEffect(() => {
        setSlideAnimation(true)
    }, [])

    const Map = ReactMapboxGl({
        accessToken,
        interactive: false,
        scrollZoom: false,
        attributionControl: false,
    })

    return (
        <Flex
            direction="column"
            style={ {height: innerHeight} }
            css={hide ? classes.hideToBottom : classes.appearToTop}
            className={styles.wrapper}>
            <Flex
                align="center"
                justify="space-between"
                alignItems="center"
                className={styles.container}>
                <Heading
                    as="h5"
                    fontSize="md">
                    {selectedActivity.name.split(' - ').map(str => <p>{str}</p>)}
                </Heading>
                <Button
                    transparent={true}
                    className={styles.close}
                    onClick={onClose}>
                        <Icon type="light/close"/>
                </Button>
            </Flex>
            <Flex
                direction="column"
                className={styles.mapWrapper}>
                <div id="map" className="mapbox">
                    {loading && (
                        <div className={`loader, ${styles.loader}`}>
                            <span className="spinner"></span>
                        </div>
                    )}
                    <div style={{ height }}>
                        {currentLocation != null && !loading && (
                            <Map
                                style="mapbox://styles/mapbox/light-v9"
                                fitBounds={calculateBounds([
                                    ...activities.map(activity => activity.loc.coordinates),
                                    bounds,
                                    currentLocation ? currentLocation.coordinates : null
                                ].filter(c => c))}
                                fitBoundsOptions={{padding: window.innerWidth <= 960 ? window.innerWidth <= 540 ? 130 : 180 : 200}}
                                containerStyle={{ width, height: '100%' }}>
                                    <MarkersOverlay
                                        currentLocation={currentLocation}
                                        activities={activities}
                                        selectedActivity={selectedActivity}
                                        calculateDistance={calculateDistance}
                                        showRoute={showRoute}
                                        profiles={profiles}
                                        details={details}
                                        geometries={geometries}
                                        getDuration={getDuration}
                                        loaded={() => { return false }}/>
                            </Map>
                        )}
                        {cityAlias && currentLocation && (
                            <a
                                href={`https://www.google.com/maps/dir/${currentLocation.coordinates[1]},${currentLocation.coordinates[0]}/${selectedActivity.loc.coordinates[1]},${selectedActivity.loc.coordinates[0]}`}
                                target="_blank"
                                className="google-map-link">
                                    <Icon
                                        type="google-maps"
                                        height={49}
                                    />
                            </a>
                        )}
                    </div>
                </div>
            </Flex>
        </Flex>
    )
}

export default MapBox