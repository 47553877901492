import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DarkLayer from '../components/DarkLayer'
import { DESKTOP, CLOUDINARY_URL } from '../constants'
import { useIosToolbar, usePedSettings } from '../hooks'
import styles from './index.module.scss'

const DEFAULT_BACKGROUND = 'black'
const ANIMATION_DURATION = {
    '/': '0',
    DEFAULT: 1000,
}
export const BACKGROUNDS = {
    '/': DESKTOP ? 'url(/images/home.jpg)' : 'url(/images/sm/home.jpg)',
    '/traveling-for': DESKTOP ? 'url(/images/step1.jpg)' : 'url(/images/sm/step1.jpg)',
    '/preferences': DESKTOP ? 'white' : 'white',
    '/results': DESKTOP ? 'white' : 'black',
}

const BackgroundProvider = ({ children }) => {
    const location = useLocation()
    const timeoutRef = useRef({})
    const settings = usePedSettings()
    const [backgroundImage, setBackground] = useState(DEFAULT_BACKGROUND)
    useIosToolbar()

    useEffect(() => {
        clearTimeout(timeoutRef.current)

        const SETTINGS_BACKGROUNDS = {
            '/': settings.welcomeImage ? `url(${CLOUDINARY_URL}/${settings.welcomeImage})` : false,
            '/traveling-for':settings.travelingForImage ? `url(${CLOUDINARY_URL}/${settings.travelingForImage})` : false,
        }

        const background = SETTINGS_BACKGROUNDS[location.pathname] || BACKGROUNDS[location.pathname] || DEFAULT_BACKGROUND
        timeoutRef.current = setTimeout(
            () => {
                setBackground(background)
            },
            ANIMATION_DURATION[location.pathname] 
                ? ANIMATION_DURATION[location.pathname]
                : ANIMATION_DURATION['DEFAULT'],
        )

    }, [location])

    return (
        <div>
            {children}
            <div
                style={{
                    backgroundImage: backgroundImage.includes('/') ? backgroundImage : '',
                    backgroundColor: !backgroundImage.includes('/') ? backgroundImage : '',
                    zIndex: !backgroundImage.includes('/') ? -1 : '',
                    height: window.innerHeight
                }}
                className={styles.backgroundsWrapper}/>
        </div>
    )
}

export default BackgroundProvider
