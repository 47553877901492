export const CLOUDINARY_URL = 'https://res.cloudinary.com/dbur3sjwh/image/fetch'
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoicmlsZXlqYW1lc21pbGhlbSIsImEiOiJjaXVvbDZwYncwMXAzMnlxbWo1dWc1d3p5In0.WZSAaZ_og6vEJuyhwuxzDg'

export const DESKTOP = window.innerWidth > 691

export const KEYWORDS = [
    [
        'shopping',
        'nature',
        'cultural',
        'arts',
        'food',
        'relaxing',
        'thrills',
        'historical',
        'beauty',
    ],
    [
        'souvenirs',
        'romantic',
        'polynesia',
        'beauty',
        'bars',
        'games',
        'thriftstores',
        'dining',
        'museum',
    ],
]

export const COVERS = {
    welcomeImage: '/images/home.jpg',
    travelingForImage: 'images/home.jpg',
    airlineLogo: '/icons/light/ten.svg',
}