/* eslint-disable */
import React, { useState, createContext, useContext } from 'react'

const AnimationsContext = createContext(null)

export const useAnimations = () => {
    const animations = useContext(AnimationsContext)

    return animations
}

const withAnimations = (Component) => ({
    ...props
}) => {
    const [animations, setAnimations] = useState({})

    return (
        <AnimationsContext.Provider value={{ animations: animations.all, activeIndex: animations.activeIndex, setAnimations }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props}/>
        </AnimationsContext.Provider>
    )
}

export default withAnimations
