/** @jsx jsx */
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/core'
import { Flex, Box, Heading, Text } from '@chakra-ui/react'
import { usePedSettings } from '../../hooks'
import { CLOUDINARY_URL } from '../../constants'
import { Icon, Button } from '../Theme'
import DarkLayer from '../DarkLayer'
import { BACKGROUNDS } from '../../providers/backgroundProvider'
import styles from './index.module.scss'

// +TODO: for animations try this library https://www.framer.com/motion/ or let's think about some other solution
const classes = {
    heading: css`
        opacity: 0;
        animation: slideUp 1s forwards;
    `,
    welcomeMessage: css`
        opacity: 0;
        animation: slideDown 1s forwards;
    `,
    content: css`
        opacity: 0;
        animation: slideDown 1s forwards;
        animation-delay: 0.5s;
    `,
    button: css`
        opacity: 0;
        animation: slideDown 1s forwards;
        animation-delay: 1s;
    `,
    slideUpLeft: css`
        animation: slideUpLeft 1s forwards;
    `,
    fadeOut: css`
        animation: fadeOut 1s forwards;
    `,
    headerIconWrapper: css`
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    `,
    footer: css`
        text-align: center;
    `,

}

const Welcome = () => {
    const [hidden, hide] = useState()
    const history = useHistory()
    const width = window.innerWidth
    const { innerHeight } = window
    const pedSetting = usePedSettings()

    const navigate = () => {
        hide(true)
        setTimeout(() => {
            history.push('/traveling-for', { secondRender: false })
        }, 1000)
    }

    const backgroundImage = pedSetting.welcomeImage ? `url(${CLOUDINARY_URL}/${pedSetting.welcomeImage})` : BACKGROUNDS['/']

    return (
        <Flex
            align="center"
            direction="column"
            justify="space-between"
            px="8"
            style={{
                height: innerHeight,
                backgroundImage,
            }}
            className={styles.home}>
            <DarkLayer/>
            <Flex
                width="100%"
                marginTop="21px"
                direction="row">
                <a
                    href="https://panasonic.aero"
                    className={styles.back}>
                    {'< Back'}
                </a>
                <Icon
                    type="light/logo"
                    className={styles.logo}/>
            </Flex>
            <Flex
                className={styles.welcome}
                direction="column"
                align="center"
                position="relative"
                zIndex="2"
                justify="center">
                <Heading
                    as="h1"
                    mb={10}
                    fontSize={width > 860 ? 'h1' : '3em'}
                    color="white.200"
                    css={hidden ? classes.slideUpLeft : classes.heading}>
                    {pedSetting.welcomeText1 || 'Aloha!'}
                </Heading>
                <Heading
                    as="h2"
                    fontSize="h2"
                    fontWeight="normal"
                    color="yellow.100"
                    css={hidden ? classes.fadeOut : classes.welcomeMessage}>
                    {pedSetting.welcomeText2 || 'Welcome aboard!'}
                </Heading>
                <Text
                    color="white.100"
                    mb={10}
                    textAlign="center"
                    css={hidden ? classes.fadeOut : classes.content}>
                    {pedSetting.welcomeText3 || 'We’d love to show what’s waiting for you in Lanai!'}
                </Text>
                <Button
                    css={hidden ? classes.fadeOut : classes.button}
                    onClick={navigate}>
                    <span className={styles.button}>
                        {pedSetting.welcomeButton || 'Let`s go'}
                    </span>
                </Button>
                <Flex
                    className={styles.anonymus}
                    w="350px"
                    justify="center"
                    mt="8"
                    mb="6">
                    <Box
                        css={classes.footer}
                        as="span"
                        fontSize="sm"
                        color="grey.200">
                        No, Thanks But I’d Like To
                        {' '}
                        <br/>
                        {' '}
                        Browse Anonymously
                    </Box>
                    {/* <Icon type="light/chat"/> */}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Welcome
