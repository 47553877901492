/* eslint-disable */
import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'

const Overlay = ({
    light
}) => {
    const [animating, animated] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            animated(false)
        }, 1100)
    }, [])

    if (!animating) {
        return <></>
    }

    return (
        <div
            className={styles.overlay}
            data-light={light}/>
    )
}

export default Overlay
