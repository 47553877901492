import React from 'react'
import styles from './index.module.scss'

const RemoveOrReplace = () => (
    <div className={styles.main}>
        <h3>Replace Activity?</h3>
        <div className={styles.results}>
            <div
                data-replace="true"
                className={styles.button}
                style={{ backgroundColor: '#000000' }}>
                <span data-replace="true">YES</span>
            </div>
            <div
                data-dialog="false"
                className={styles.button}
                style={{ backgroundColor: '#d3d3d3', color: '#000000' }}>
                <span data-dialog="false">NO</span>
            </div>
        </div>
    </div>
)

export default RemoveOrReplace
