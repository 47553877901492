import { useCallback } from 'react'
import axios from 'axios'
import useFetcher from './useFetcher'

const url = 'https://hf74ybwurh.execute-api.us-east-1.amazonaws.com'
const query = `query getBySlug($slug: String){
    getBySlug(slug: $slug) {
        welcomeText1
        welcomeText2
        welcomeText3
        welcomeImage
        welcomeButton
        travelingForImage
        travelingForText
        question1
        question2
        question3
        keywords{
            shopping
            nature
            cultural
            arts
            food
            relaxing
            thrills
            historical
            souvenirs
            romantic
            museum
            beauty
            bars
            games
            thriftstores
            dining
        }
        airlineLogo
        resultMessage
        loadingText
        resultText
    }
}`

const useGetPedSettings = () => {
    const { data, loading, error, fetcher } = useFetcher()

    const fetch = useCallback(
        (cityCollection) => {
            const requestData = {
                query,
                operationName: 'getBySlug',
                variables: { slug: cityCollection },
            }

            fetcher(
                axios({
                    url,
                    method: 'POST',
                    data: requestData,
                }),
            )
        },
        [fetcher],
    )

    return {
        data: data?.data?.data?.getBySlug,
        fetch,
        loading,
        error,
    }
}

export default useGetPedSettings
