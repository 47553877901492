import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import Activity from './Activity/index'
import Map from './Map'
import { useAnimations } from '../../hooks'
import Loading from '../Loading'
import styles from './index.module.scss'

const Animations = () => {
    const { animations, activeIndex, setAnimations } = useAnimations()
    const [loading, toggleLoading] = useState(true)
    const history = useHistory()
    const [activity, setActivity] = useState(activeIndex || 0)
    const [slide, setSlide] = useState(null)
    const [map, setMap] = useState()
    const [hideAnimations, setHideAnimations] = useState(false)

    const close = () => setAnimations({})

    // eslint-disable-next-line no-shadow
    const openMap = ({ slide, activity }) => {
        setMap({
            slide: animations[activity][slide],
            slideIndex: slide,
            activityIndex: activity,
        })
    }

    const onMapClose = () => {
        setActivity(map.activityIndex)
        setSlide(map.slideIndex)
        setMap(null)
        setHideAnimations(false)
    }

    const onEnd = useCallback(
        () => {
            if (activity + 1 === animations.length) {
                return close()
            }
            setActivity(activity + 1)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activity, setActivity],
    )

    const onPrev = useCallback(
        () => {
            if (activity === 0) {
                return close()
            }
            setActivity(activity - 1)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activity, setActivity],
    )

    const swipe = useCallback(
        (direction) => {
            if (direction === 'Right') {
                return onPrev()
            }
            onEnd()
        },
        [onEnd, onPrev],
    )

    useEffect(() => {
        // its required for safari toolbar don`t show content before toolbar now enabled
        setTimeout(() => toggleLoading(false), 0)
    }, [])

    useEffect(() => {
        if (!map) return
        setTimeout(() => {
            setHideAnimations(true)
        }, 1000)
    }, [map])

    if (!animations) {
        if (!history.location?.state?.from && !history.location?.state?.tab) return history.goBack()

        history.push(history.location.state.from.pathname + history.location.state.from.search, { tab: history.location.state.tab })

        return <></>
    }

    return (
        <>
            {map && !loading && (
                <Map
                    selectedActivity={map.slide}
                    activities={[map.slide]}
                    // eslint-disable-next-line no-underscore-dangle
                    cityAlias={map.slide._city.name.toLowerCase().split(' ').join('-')}
                    showRoute
                    close={onMapClose}/>
            )}
            {!hideAnimations && !loading && (
                <Flex
                    justify="space-between"
                    direction="column"
                    color="white.100"
                    style={map ? { zIndex: 0 } : {}}
                    className={styles.wrapper}>
                    <Activity
                        nextSlides={animations[activity + 1]}
                        slides={animations[activity]}
                        slideActiveIndex={slide}
                        onEnd={onEnd}
                        onPrev={onPrev}
                        openMap={(data) => openMap({ ...data, activity })}
                        onClose={close}
                        swipe={swipe}/>
                </Flex>
            )}
            {loading && (
                <>
                    <Loading/>
                </>
            )}
        </>
    )
}

export default Animations
